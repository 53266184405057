
.best-plan-card {
    border-radius: 72px;
    border: 3px solid #CBCBCB;
    background: #E9E9E9;
    height: auto;
    padding-inline: 6.2rem;
    width: 100%;
    transition: all 0.5s ease;
}

.best-plan-card:hover{
    border: 3px solid #ef6923;
    box-shadow: 0 0 .8rem .7rem rgba(239,105,35,0.2);
}




.best-plan-card .card-img {
    width: 100%;
    height: 419px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.best-plan-card .card-img img{
    position: absolute;
    top: -8.5%;
    width: 420px;
    height:300px;
    border-radius: 5rem;
}

.best-plan-card .card-body{
    position: relative;
    top: -5%;
}


.best-plan-card .card-text{
    display: grid;
    gap: 2.5rem;
}

.best-plan-card .card-title {
    color: #05294C;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    text-transform: uppercase;
}

.best-plan-card .card-desc {
    color: #000;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 38.4px */
    text-transform: capitalize;
    height: 14rem;
}

.best-plan-card .card-box{
    margin-top: 6.5rem;
}

@media (width<=1440px) {
    .best-plan-card .card-img img {
        max-width: 100%;
    }
    .best-plan-card .card-box{
        margin-top: 5rem;

    }
}

@media (width <=1280px) {
    .best-plan-title{
         margin-bottom: 8rem !important;
    }
    .best-plan-card{
        padding-inline: 4rem;
        padding-bottom: 4rem;
    }
    .best-plan-card .card-title {
        font-size: 30px;
        font-weight: 800;
        line-height: normal; /* 72.96px */
    }
    .best-plan-card .card-desc {

        font-size: 22px;
        font-weight: 400;
        line-height: normal; /* 38.4px */
        /*height: auto;*/
    }
    .best-plan-card .card-box{
        margin-top: 2rem;
    }
    .best-plan-card .card-img img{
        position: unset;
        /*top: 0;*/
    }

    .best-plan-card .card-body{
        position: unset;
    }
}

@media (width <= 1024px) {
    .best-plan-title{
        margin-bottom: 5rem !important;
        font-size: 35px !important;
    }
    .best-plan-card{
        padding: 0 2rem 3rem 2rem;
    }
    .best-plan-card .card-img img{
        position: unset;
        /*top: 0;*/
    }

    .best-plan-card .card-body{
        position: unset;
    }
    .best-plan-card .card-img {
        display: flex;
        align-items: center;
    }
    .best-plan-card .card-img img {
        max-width: 100%;
    }

}

@media (width<900px) {
    .best-plan-card .card-box{
        margin-top: 4rem;
    }
    .best-plan-card .card-img{
        height: 380px;
    }
}


@media (width<768px) {
    .best-plan-card .card-img {
       height: auto;
    }
    .best-plan-card .card-img img{
        margin: 4rem 0;
    }
    .best-plan-card:nth-child(2) .card-img{
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .best-plan-card .card-desc {

        font-size: 18px;
    }
}

@media (width<=375px) {
    .best-plan .best-plan-card {
        padding-inline: 1rem;
    }
}

@media(width>1200px) and (width<1550px){
    .best-plan-card .card-title {
        font-size: 40px;
    }
    .best-plan-card .card-desc {
        font-size: 25px;
    }
    .best-plan-card .card-text {
        gap: 1.5rem;
    }
}