.total-bundils-for-background {
  background: linear-gradient(180deg, #4a658f 0%, #012548 100%);
}
.total-bundils-for-background .bundils-for-total-content {
  display: flex;
  flex-direction: column;
  gap: 4.6rem;
  justify-content: center;
  align-items: center;
  padding: 11rem 10rem;
}
.bundils-for-total-content .three-layer-text {
  display: flex;
  flex-direction: column;
  gap: 2.6rem;
  justify-content: center;
  align-items: center;
}
.three-layer-text .first-bundil-text {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 82px;
  font-style: normal;
  font-weight: 700;
  line-height: 100.5%; /* 82.41px */
  text-transform: capitalize;
  max-width: 1324px;
}
.three-layer-text .second-bundil-text {
  color: #e3e3e3;
  text-align: center;
  font-family: Poppins;
  font-size: 33px;
  font-style: normal;
  font-weight: 500;
  line-height: 134.5%; /* 44.385px */
  text-transform: capitalize;
  max-width: 1000px;
}

.three-layer-text .third-bundil-text {
  color: #fefefe;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100.5%;
  text-transform: capitalize;
  max-width: 1324px;
}

@media (width<=768px) {
  .total-bundils-for-background .bundils-for-total-content {
    padding: 4rem 2rem;
  }
  .three-layer-text .first-bundil-text {
    font-size: 55px;
  }
  .three-layer-text .second-bundil-text {
    font-size:25px;
  }
  .three-layer-text .third-bundil-text {
    font-size: 20px;
    line-height:30px;
  }
}

@media(width<600px){
  .three-layer-text .first-bundil-text {
    font-size: 40px;
  }
  .three-layer-text .second-bundil-text {
    font-size: 20px;
    line-height: 32px;
}
.three-layer-text .third-bundil-text {
  font-size: 18px;
}
  /* .three-layer-text .second-bundil-text {
    font-size: 20px;
  }
  .three-layer-text .third-bundil-text {
    font-size: 16px;
  } */
}

@media(width>1200px) and (width<1550px){
  .three-layer-text .first-bundil-text {
    font-size: 60px;
  }
  .three-layer-text .second-bundil-text {
    font-size: 24px;
  }
  .three-layer-text .third-bundil-text {
    font-size: 20px;
  }
}