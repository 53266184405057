.total-wait-container {
  padding: 10rem 4rem;
  background: #fff;
}
.total-wait-container .wait-container-content {
  border-radius: 95px;
  border: 3px solid #f00;
  background: #fff4df;
  max-width: 1329px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.1rem;
  flex-direction: column;
}

.wait-container-content .top-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.9rem;
}
.top-content .motive-text {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%; /* 48.64px */
  max-width: 1100px;
}
.top-content .motive-text span {
  color: #05294c;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%;
  text-decoration-line: underline;
}

.top-content .header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  flex-direction: column;
  padding: 0 1rem;
}
.top-content .header-content .header-sub-text-2 {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 152%;
}
.top-content .header-content .header-sub-text-2 span {
  color: #e99b07;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%;
}
.header-content .header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}
.header-content .header-text h1 {
  color: #05294c;
  text-align: center;
  font-family: Poppins;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%; /* 145.92px */
  text-transform: uppercase;
}

.header-content .header-text p {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%; /* 72.96px */
  max-width: 1100px;
}
.wait-container-content .down-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  /* width: 100%; */
  margin: 1rem;
  @media(width>1260px){
    gap: 10rem;
  }
}
.down-content .image-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.down-content .content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.1rem;
  padding: 0 0.5rem;
}
.down-content .content-container .header-content {
  display: flex;
  flex-direction: column;
  gap: 2.6rem;
  justify-content: center;
  align-items: center;
}
.content-container .header-content .text {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%; /* 48.64px */
  max-width: 569px;
}
.content-container .header-content .text span {
  color: #05294c;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%;
}
.header-content .value-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 569px;
  border-radius: 20px;
  background: #e99b07;
  box-shadow: 0px 7px 21.2px 3px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  width: 100%;
  cursor: pointer;
}

.value-button .letter-style {
  color: #05294c;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%;
}
.value-button .letter-style span {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%; /* 97.28px */
  text-decoration-line: line-through;
}
.value-button .sub-text {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 152%; /* 42.56px */
}
.content-container .bottom-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-container .bottom-text .small-text {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 119%; /* 33.32px */
  text-transform: uppercase;
}
.content-container .bottom-text .big-text {
  color: #05294c;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 119%;
  text-transform: uppercase;
  max-width: 577px;
}

.image-container .image-style {
  width: 100%;
  max-width: 500px;
}
@media (width<1250px) {
  .wait-container-content .down-content {
    flex-direction: column;
    padding: 1rem;
  }
}
@media (width<768px) {
  .value-button .letter-style .value-button .letter-style span {
    font-size: 50px;
  }
  .header-content .value-button {
    width: 90%;
  }
  .total-wait-container {
    padding: 4rem 2rem;
  }
  .header-content .header-text h1 {
    font-size: 50px;
  }
  .header-content .header-text p {
    font-size: 30px;
  }
}

@media (width<600px) {
  .value-button .letter-style span {
    font-size: 50px;
  }
  .value-button .letter-style {
    font-size: 50px;
  }
  .value-button .sub-text {
    font-size: 2rem;
  }
  .content-container .bottom-text .big-text {
    font-size: 35px;
  }
  .content-container .header-content .text span {
    font-size: 40px;
  }
  .header-content .header-text h1 {
    font-size: 30px;
  }
  .header-content .header-text p {
    font-size: 20px;
  }
  .top-content .header-content .header-sub-text-2 {
    font-size: 16px;
  }
  .top-content .header-content .header-sub-text-2 span {
    font-size: 18px;
  }
  .content-container .header-content .text ,.top-content .motive-text span ,.top-content .motive-text {
    font-size: 20px;
  }
  .value-button .letter-style  ,.value-button .letter-style span , .content-container .header-content .text span {
    font-size: 30px;
  }
}
@media (width<450px) {
  /* .value-button .letter-style span {
    font-size: 40px;
  } */
  /* .value-button .letter-style {
    font-size: 40px;
  } */
  .content-container .bottom-text .big-text {
    font-size: 2rem;
  }
  .value-button .sub-text {
    font-size: 1.6rem;
  }
}
@media (width<380px) {
  .value-button .letter-style {
    font-size: 30px;
  }
  .value-button .letter-style span {
    font-size: 30px;
  }
}


@media(width>1200px) and (width<1550px){
  .header-content .header-text h1 {
    font-size: 60px;
    /* line-height: 0px; */
  }
  .header-content .header-text p {
    font-size: 30px;
  }
  .value-button .letter-style span  ,.value-button .letter-style ,.content-container .header-content .text span {
    font-size: 45px;
  }
  .content-container .bottom-text .big-text {
    font-size: 40px;
  }
  .image-container .image-style {
    height: 380px;
  }
  .top-content .motive-text {
    font-size: 24px;
  }
  .total-wait-container .wait-container-content {
    gap: 2.1rem;
  }
}