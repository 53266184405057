.qna-container{
    background: linear-gradient(180deg, #48638D 0%, #04284B 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.qna{
    width: 100%;
    padding: 8.4rem 22rem;
    max-width: 1600px;
    display: grid;
    gap: 4rem;
}
.all-qna{
    display: grid;
    gap: 2.4rem;
}

.qna-title{
    color: #FCCA68;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 77px; /* 128.333% */
}


.qna-support .des{
    color: #FFF;
    text-align: center;
    font-family: Poppins, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
}

.qna-support .email-id-text{
    color: #FFF;
    font-family: Poppins, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
}

.qna-support .email-id-text span{
    color: #FECF73;
    font-family: Poppins, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
}

@media (width <=1280px) {
    .qna{
        padding: 8.4rem 11rem;
    }
    .qna-title{
        font-size: 50px;
        line-height: 60px;
    }
    .qna-support .des{
        font-size: 22px;
    }

    .qna-support .email-id-text{
        font-size: 35px;
    }
    .qna-support .email-id-text span{
        font-size: 35px;
    }
}
@media (width <=992px) {
    .qna{
        padding: 8.4rem 7rem;
    }
    .qna-title{
        font-size: 40px;
        line-height: 50px;
    }
    .qna-support .des{
        color: #FFF;
        font-size: 18px;
    }

    .qna-support .email-id-text{
        font-size: 30px;
    }
    .qna-support .email-id-text span{
        font-size: 30px;
    }
}

@media (width <=850px) {
    .qna{
        padding: 8.4rem 4rem;
    }
    .qna-title{
        font-size: 35px;
        line-height: 45px;
    }
    .qna-support .email-id-text{
        font-size: 25px;
    }
    .qna-support .email-id-text span{
        font-size: 25px;
    }
}

@media (width <=500px) {
    .qna{
        padding: 8.4rem 2rem;
    }
    .qna-title{
        font-size: 30px;
        line-height: 40px;
    }
    .qna-support .des{
        font-size: 15px;
    }
    .qna-support .email-id-text{
        font-size: 22px;
    }
    .qna-support .email-id-text span{
        font-size: 22px;
    }
}

@media(width>1200px) and (width<1550px){
    .qna {
        gap: 2.5rem;
    }
    .all-qna {
        gap: 1.5rem;
    }
    .qna-list .question {
        font-size: 20px;
    }
    
}