.total-grid-book-container {
  background: #fff;
}
.total-grid-book-container .total-grid-container-content {
  display: flex;
  flex-direction: column;
  gap: 4.9rem;
  padding: 10rem 4rem;
  max-width: 1400px;
  margin: auto;
}
.total-grid-container-content .grid-box-header-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.grid-box-header-text .first-text {
  color: #04274b;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
}
.grid-box-header-text .second-text {
  color: #04274b;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  text-align: center;
}
.total-grid-container-content .grid-books-division {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 4rem;
  justify-content: center;
  /* align-items: center; */
}
.grid-books-division .single-card {
  border: 1px solid #cbcbcb;
  background: #fefefe;
  /* max-width: 413px; */
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
  /* width: 100%; */
  border: 1px solid;
}
.single-card .card-image {
  width: 100%;
  max-width: 322px;
  height: 300px
}
.single-card .book-count-container {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0.24px;
  max-width: 272px;
  height: 50.054px;
  border-radius: 24.5px;
  background: #04274b;
  width: 100%;
}
.single-card .card-text {
  color: var(--Black, #403c39);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.total-grid-container-content .many-more-text {
  display: flex;
  gap: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.total-grid-container-content .many-more-text .text {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px; /* 130% */
}
.total-grid-container-content .many-more-text .buynow-button {
  border-radius: 38px;
  background: linear-gradient(180deg, #fece71 0%, #eba011 100%);
  box-shadow: 0px 4px 12.5px 1px rgba(0, 0, 0, 0.25);
  max-width: 498px;
  height: 76px;
  flex-shrink: 0;
  color: #04274b;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 52px; /* 130% */
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.grid-books-division .single-grid-book{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F2FFFE;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
}
.single-grid-book .ebook-header{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: #202020;
  width: 100%;
  border-radius: 20px 20px 0px 0px
}
.ebook-header .icon-style{
  font-size: 1rem;
  color: #ffc107;
}
.ebook-header p{
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 800;
}
.single-grid-book .bottom-text{
  color: #B30C99;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  padding: 0 .5rem;

}
.single-grid-book .grid-box-image{
  width: 100%;
  height: 225px;
  @media(width<1400px){
    height: 200px;
  }
}
@media(width<1200px){
  .single-grid-book .grid-box-image{
    height: 175px;
  }
  .single-card .card-image {
    height: 230px;
  }
  .single-card .book-count-container {
    max-width: 200px;
  }
  .total-grid-book-container .total-grid-container-content {
    padding: 8rem 4rem;
  }
  .total-grid-container-content .grid-books-division {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media(width<1024px){
  .single-card .card-image {
    height: 190px;
  }
  .single-grid-book .grid-box-image{
    height: 200px;
  }
  .total-grid-container-content .grid-books-division {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }
  .single-card .book-count-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    height: 40.054px;
    line-height: 0px;
  }
  .single-card .card-text {
    font-size: 16px;
  }
  .grid-books-division .single-card {
    gap: 1.6rem;
  }
}
@media(width<=768px){
  .total-grid-book-container .total-grid-container-content {
    padding: 4rem;
    gap: 2.9rem;
  }
  .total-grid-container-content .many-more-text .text {
    font-size: 30px;
    line-height: unset;
  }
  .total-grid-container-content .many-more-text .buynow-button {
    height: 60px;
    font-size: 30px;
  }
  .grid-box-header-text .first-text {
    font-size: 35px;
  }
  .grid-box-header-text .second-text {
    font-size: 40px;
    line-height: 35px;
  }
  .total-grid-container-content .grid-books-division {
    gap: 1rem;
  }
}
@media(width<650px){
  .single-card .card-image {
    height: 180px;
}
.total-grid-book-container .total-grid-container-content {
  padding: 4rem 2rem;
}
.grid-box-header-text .first-text {
  font-size: 30px;
}
.grid-box-header-text .second-text {
  font-size: 35px;
}
}
@media(width<600px){
   .total-grid-container-content .grid-books-division {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .single-grid-book .grid-box-image{
    /* width: 80%; */
    height: 250px;
  }
}

@media(width<450px){
  .total-grid-container-content .grid-books-division {
    grid-template-columns:1fr;
  }
  .single-card .card-image {
    height: 250px;
}
.grid-box-header-text .first-text {
  font-size: 25px;
}
.grid-box-header-text .second-text {
  font-size: 30px;
}
.single-grid-book .grid-box-image{
  /* width: 80%; */
  height: 275px;
}
}

@media(width>1200px) and (width<1550px){
  .three-layer-text .third-bundil-text {
    font-size: 35px;
  }
  .grid-box-header-text .second-text {
    font-size: 40px;
  }
  .grid-box-header-text .first-text {
    font-size: 35px;
  }
  .total-grid-container-content .many-more-text .text {
    font-size: 35px;
  }
  .total-grid-container-content .many-more-text .buynow-button {
    height: 60px;
    font-size: 35px;
    font-weight: 800;
  }
}