.total-ebook-footer {
  background-color: #021a31;;
  padding: 2.5rem;
}
.total-ebook-footer .footer-content {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: auto;
  max-width: 1100px;
  @media (width<600px) {
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;
    /* align-items: center; */
  }
  @media (width<500px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.footer-content .about-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 20%;
  color: #fff;
  @media (width<800px) {
    width: 100%;
  }
}
.footer-content .popular-courses-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 40%;
  color: #fff;
  @media (width<800px) {
    width: 100%;
  }
  @media (width<600px) {
    justify-content: center;
    align-items: center;
  }
}
.footer-content .get-touch-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.footer-content .footer-image-ebook {
  width: 13rem;
}
.about-container .header-text,
.get-touch-container .header-text {
  font-size: 18px;
  text-align: start;
  font-weight: 500;
  color: #fff;
  @media(width<=600px){
    text-align: center;
  }
}
.popular-courses-container .header-text {
  font-size: 18px;
  text-align: start;
  font-weight: 500;
  color: #fff;
  @media(width<=600px){
    text-align: center;
  }
}
.about-container .down-text,
.get-touch-container .down-text {
  font-size: 15px;
  text-align: start;
  color: #fff;
  @media(width<=600px){
    text-align: center;
  }
}
.popular-courses-container .down-text a{
  font-size: 15px;
  text-align: start;
  max-width: 166px;
  color: #fff;
  @media(width<=600px){
    text-align: center;
  }
}
.get-touch-container .all-icons-footer {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: center;
  /* align-items: center; */
}
.all-icons-footer .social-icon-with-text {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.all-icons-footer .social-icon-with-text p a{
  font-size: 1.5rem;
  color: #fff;
}
.all-icons-footer .social-icon-with-text .icon-size{
  font-size: 1.5rem;
  color: #0a66c2;
}
