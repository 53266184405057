@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}

html {
    font-size: 62.5%;
}

.best-plan {
    background: #ffffff;
    position: relative;
}

.best-plan-title {
    color: #000;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%; /* 72.96px */
    text-transform: capitalize;
    margin-bottom: 18.4rem;
}

.best-plan .best-plan-content {
    max-width: 160rem;
    padding-inline: 13.4rem 13.7rem;
    display: block;
    margin: auto;
    position: relative;
}

.best-plan-card-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 2.7rem;
}

.best-plan-card:nth-child(2) {
    border: 3px solid #E99B07;
    transition: all 0.5s ease;
}

.best-plan-card:nth-child(2):hover {
    border: 3px solid #ef6923;
    box-shadow: 0 0 .8rem .7rem rgba(239, 105, 35, 0.2);
}


.best-plan-card:nth-child(1) .feature-card .single-list:nth-child(2) {
    text-decoration: line-through;
}

.best-plan-card:nth-child(1) .feature-card .single-list:nth-child(4) {
    text-decoration: line-through;
}

.best-plan-card .feature-card .single-list:nth-child(3) {
    font-weight: 700;
}

@media (width <= 1024px) {
    .best-plan .best-plan-content {
        padding-inline: 3rem;
    }

    .best-plan-card-wrapper {
        gap: 1.7rem;
    }
}

@media (width < 768px) {
    .best-plan-card-wrapper {
        grid-template-columns: 100%;
        gap: 8rem;
    }

    .best-plan-card .card-desc {
        height: auto;
    }
    .best-plan {
        padding: 2rem;
    }
    .best-plan .best-plan-content {
        padding: unset;
    }
    .best-plan-title {
        font-size: 30px;
        line-height: 45px;
    }
}

@media (width <= 375px) {
    .best-plan .best-plan-content {
        padding-inline: unset;
    }
}

@media(width>1200px) and (width<1550px){
    .best-plan-title {
        font-size: 38px;
    }
}
