/* .buy-now-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: linear-gradient(180deg, #ffcf75 0%, #e89800 100%);
    height: 126px;
    max-width: 931px;
    cursor: pointer;
    width: 100%;
    animation: btnAnimation 5s ease infinite, shake 1.5s ease infinite;
    background: linear-gradient(60deg, #37f432, #05b2cc, #37c800, #3cd586, #49822a, #77f733, #4e570d);
    background-size: 300% 300%;
  }

 .buy-now-button:hover{
  animation: btnAnimation 5s ease infinite;
 }

  @keyframes btnAnimation {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

@keyframes shake {
  from,to {
      transform: translate3d(0,0,0)
  }

  10%,30%,50%,70%,90% {
      transform: translate3d(-10px,0,0)
  }

  20%,40%,60%,80% {
      transform: translate3d(10px,0,0)
  }
}
  .buy-now-button .value-text .normal-price{
    color: #04274b;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .buy-now-button .value-text .rack-price{
    color: #04274B;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: line-through;
  text-transform: uppercase;
  }
  .buy-now-button .first-text {
    color: #04274b;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  @media(width<870px){
    .buy-now-button .value-text .normal-price {
      font-size: 30px;
    }
    .buy-now-button .value-text .rack-price {
      font-size: 30px;
    }
  }
  @media(width<650px){
    .buy-now-button .value-text .normal-price {
      font-size: 20px;
    }
    .buy-now-button .value-text .rack-price {
      font-size: 20px;
    }
    .buy-now-button .first-text {
      font-size: 20px;
    }
    .buy-now-button {
      height: 80px;
    }
  }
  @media(width<400px){
    .buy-now-button .value-text .normal-price {
      font-size: 15px;
    }
    .buy-now-button .value-text .rack-price {
      font-size: 15px;
    }
    .buy-now-button .first-text {
      font-size: 15px;
    }
    .buy-now-button {
      height: 60px;
    }
  }

  @media(width>1200px) and (width<1550px){
    .buy-now-button {
      height: 100px;
    }
    .buy-now-button .value-text .normal-price {
      font-size: 35px;
    }
    .buy-now-button .value-text .rack-price {
      font-size: 35px;
    }
  } */





  .buy-now-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: linear-gradient(60deg, #37f432, #05b2cc, #37c800, #3cd586, #49822a, #77f733, #4e570d);
    background-size: 300% 300%;
    height: 126px;
    max-width: 931px;
    cursor: pointer;
    width: 100%;
    animation: btnAnimation 5s ease infinite;
  }
  
  .buy-now-button.shake {
    animation: btnAnimation 5s ease infinite, shake 1.5s ease;
  }
  
  .buy-now-button:hover {
    animation: btnAnimation 5s ease infinite;
  }
  
  @keyframes btnAnimation {
    0% {
      background-position: 0 50%;
    }
  
    50% {
      background-position: 100% 50%;
    }
  
    100% {
      background-position: 0 50%;
    }
  }
  
  @keyframes shake {
    from, to {
      transform: translate3d(0, 0, 0);
    }
  
    10%, 30%, 50%, 70%, 90% {
      transform: translate3d(-10px, 0, 0);
    }
  
    20%, 40%, 60%, 80% {
      transform: translate3d(10px, 0, 0);
    }
  }


    .buy-now-button .value-text .normal-price{
    color: #04274b;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .buy-now-button .value-text .rack-price{
    color: #04274B;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: line-through;
  text-transform: uppercase;
  }
  .buy-now-button .first-text {
    color: #04274b;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  @media(width<870px){
    .buy-now-button .value-text .normal-price {
      font-size: 30px;
    }
    .buy-now-button .value-text .rack-price {
      font-size: 30px;
    }
  }
  @media(width<650px){
    .buy-now-button .value-text .normal-price {
      font-size: 20px;
    }
    .buy-now-button .value-text .rack-price {
      font-size: 20px;
    }
    .buy-now-button .first-text {
      font-size: 20px;
    }
    .buy-now-button {
      height: 80px;
    }
  }
  @media(width<400px){
    .buy-now-button .value-text .normal-price {
      font-size: 15px;
    }
    .buy-now-button .value-text .rack-price {
      font-size: 15px;
    }
    .buy-now-button .first-text {
      font-size: 15px;
    }
    .buy-now-button {
      height: 60px;
    }
  }

  @media(width>1200px) and (width<1550px){
    .buy-now-button {
      height: 100px;
    }
    .buy-now-button .value-text .normal-price {
      font-size: 35px;
    }
    .buy-now-button .value-text .rack-price {
      font-size: 35px;
    }
  }


  
