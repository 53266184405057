.total-clock-background {
  background: linear-gradient(180deg, #49648e 0%, #03274a 100%);
}
.total-clock-background .clock-container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding:6.9rem 4rem;
}
.clock-container-content .clock-text p {
  max-width: 785px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px; /* 130% */
  text-transform: uppercase;
}
.clock-container-content .clock-text .span-text {
  color: #fff;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  text-transform: uppercase;
}
.total-clock-background .real-time-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5.3rem;
}
.real-time-container .clock-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.clock-content .number-text{
    color: #FCCA68;
    text-align: center;
    font-family: Poppins;
    font-size: 128px;
    font-style: normal;
    font-weight: 700;
    line-height: 150px; /* 117.188% */
    text-transform: uppercase;
}
.clock-content .value-text{
    color: #7BA2DD;
text-align: center;
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 138.889% */
text-transform: uppercase;
}

@media(width<1000px){
  .clock-content .number-text {
    font-size: 80px;
    line-height: 100px;
  }
  .clock-content .value-text {
    font-size: 30px;
    line-height: 30px;
  }
}
@media(width<768px){
  .total-clock-background .real-time-container {
    gap: 3rem;
  }
  .total-clock-background .clock-container-content {
    padding: 4rem 2rem;
  }
}
@media(width<600px){
  .total-clock-background .clock-container-content {
    padding: 2rem 1rem;
  }
  .clock-container-content .clock-text p {
    font-size: 25px;
    line-height: 30px;
}
.clock-content .number-text {
  font-size: 60px;
  line-height: 80px;
}
}
@media(width<500px){
  .clock-content .value-text {
    font-size: 20px;
    line-height: 0px;
}
.clock-content .number-text {
  font-size: 45px;
  line-height: 75px;
}
.clock-container-content .clock-text .span-text {
  font-size: 30px;
}
.total-clock-background .real-time-container {
  gap: 2rem;
}
}

@media(width>1200px) and (width<1550px){
  .clock-container-content .clock-text p {
    font-size: 30px;
  }
  .clock-content .number-text {
    font-size: 100px;
    line-height: 100px;
  }
  .clock-content .value-text {
    font-size: 30px;
  }
  .total-clock-background .real-time-container {
    gap: 3.3rem;
  }
}