.total-claim-container {
  background: #fff;
  padding: 11rem 4rem;
}
.total-claim-container .claim-container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6.4rem;
}
.total-claim-container .claim-container-content .header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.claim-container-content .header-text .first-text {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 77px; /* 160.417% */
  max-width: 1100px;
}
.claim-container-content .header-text .second-text {
  max-width: 1100px;
  color: #05294c;
  text-align: center;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: 77px; /* 120.313% */
}
.claim-container-content .claim-container {
  border-radius: 20px;
  background: #e99b07;
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.25);
  max-width: 934px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #04274b;
  text-align: center;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 77px; /* 160.417% */
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
}

@media (width<768px) {
  .claim-container-content .claim-container {
    font-size: 40px;
  }
  .total-claim-container {
    padding: 4rem;
  }
}
@media (width<650px) {
  .claim-container-content .claim-container {
    font-size: 30px;
    line-height: 60px;
  }
  .claim-container-content .header-text .first-text {
    font-size: 40px;
    line-height: 45px;
  }
  .claim-container-content .header-text .second-text {
    font-size: 50px;
    line-height: 65px;
  }
  .total-claim-container .claim-container-content {
    gap: 3.4rem;
  }
  .total-claim-container {
    padding: 4rem 2rem;
  }
}
@media (width<500px) {
  .claim-container-content .claim-container {
    font-size: 20px;
    line-height: 40px;
  }
  .claim-container-content .header-text .second-text {
    font-size: 30px;
    line-height: 40px;
  }
  .claim-container-content .header-text .first-text {
    font-size: 25px;
    line-height: 40px;
  }
}
@media(width<375px){
  .claim-container-content .claim-container {
    font-size: 15px;
    line-height: 30px;
}
}


@media(width>1200px) and (width<1550px){
  .claim-container-content .header-text .first-text {
    font-size: 40px;
  }
  .claim-container-content .header-text .second-text {
    font-size: 50px;
  }
  .total-claim-container .claim-container-content {
    gap: 4.4rem;
  }
  .claim-container-content .claim-container {
    font-size: 40px;
    line-height: 60px;
  }
}