.total-seventeen-books-background {
  background: #fff;
}
.total-seventeen-books-background .books-container-with-text {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
  padding: 8rem;
  max-width: 1300px;
  margin: auto;
}
.books-container-with-text .books-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.books-text .first-text {
  color: #022548;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 100.5%;
  text-transform: uppercase;
  white-space: nowrap;
}

.books-text .second-text {
  color: #04284b;
  font-family: Poppins;
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  line-height: 100.5%;
  text-transform: uppercase;
  white-space: nowrap;
}
.books-container-with-text .image-style {
  width: 100%;
}

@media (width<1200px) {
  .total-seventeen-books-background .books-container-with-text {
    flex-direction: column;
  }
  .books-container-with-text .image-style {
    width: 100%;
    max-width: 943px;
  }
}
@media (width<1024px) {
  .books-text .first-text {
    font-size: 40px;
  }
  .books-text .second-text {
    font-size: 60px;
  }
  .total-seventeen-books-background .books-container-with-text {
    padding: 8rem 4rem;
  }
}
@media (width<768px) {
  .total-seventeen-books-background .books-container-with-text {
    padding: 4rem 2rem;
  }
}
@media (width<600px) {
  .books-text .second-text {
    font-size: 35px;
    white-space: unset;
  }
  .books-text .first-text {
    font-size: 20px;
    white-space: unset;
  }
}

@media (width>1200px) and (width<1550px) {
  .books-text .first-text {
    font-size: 35px;
  }
  .books-text .second-text {
    font-size: 60px;
  }
}

