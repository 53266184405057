

.qna-list{
    border-radius: 15px;
    /*background: #04284B;*/
    /*padding: 2.3rem 3rem;*/
    /*display: grid;*/
    /*gap: 2rem ;*/
    overflow: hidden;
}

.qna-list .show-ans{
    animation: slideDown 0.5s linear;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px); /* Adjust for desired distance */
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.qna-list .question{
    display: flex;
    align-items: center;
    gap: 4rem;
    color: #FFF;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    /*line-height: 77px; !* 320.833% *!*/
    cursor: pointer;
    padding: 1.5rem 3rem;
    background: #04284B;
}
.qna-list .ans{
    display: flex;
    gap: 4rem;
    color: #04284B;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    background: white;
    padding: 1.5rem 3rem;
}
.icon{
    width: 40px;
    height: 40px;
    background: #FECF73;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    transition: all 0.5s linear;
}
.ans .icon{
    visibility: hidden;
}

@media (width <=992px) {

    .qna-list .question{
       padding : 1rem 2rem
    }
    .qna-list .ans{
        padding : 1rem 2rem;
    }

}

@media (width <=800px) {
    .icon{
        width: 30px;
        height: 30px;
    }

    .qna-list .question{

        gap: 2rem;
        font-size: 20px;
    }
    .qna-list .ans{
        gap: 2rem;
        font-size: 15px;
    }
}

@media (width <= 500px) {
    .qna-list .question{
        gap: 1.8rem;
        font-size: 18px;
    }
    .qna-list .ans{
        gap: 1.8rem;
        font-size: 14px;
    }
}

@media (width <= 375px) {
    .qna-list .question{
        gap: 1.5rem;
        font-size: 15px;
        padding:  1rem
    }
    .qna-list .ans{
        gap: 1.5rem;
        font-size: 12px;
        padding: 1rem
    }
}

@media(width>1200px) and (width<1550px){
    .icon{
        width: 30px;
        height: 30px;
    }
}