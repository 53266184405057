.feature-card {
    border-radius: 48px;
    background: #05294C;
    padding: 3.1rem 4.5rem 4.8rem 5rem;
}

.feature-card .feature-card-title{
    color: #FEFEFE;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 128%; /* 51.2px */
    text-transform: capitalize;
}

.single-list{
    display: flex;
    gap: 2.1rem;
    color: #EAE9E9;
    font-family: Inter, serif;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-items: center;
}
.single-list p{
    color: #EAE9E9;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: strikethrough;
    @media(width<768px){
        font-size: 1.6rem;
    }
}

.feature-lists{
    display: grid;
    gap: 2.4rem;
    margin-top: 4.7rem;
}

.feature-card .price{
    display: flex;
    align-items: end;
    gap: 1.5rem;
    margin-top: 3.3rem;
    justify-content: center;
}

.feature-card .real-price{
    color: #AAA;
    text-align: center;
    font-family: Inter, serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: .5rem;
    text-decoration: line-through;
}
.feature-card .offer-price{
    color: #FCCA68;
    font-family: Inter, serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.feature-card .feature-last{
    display: grid;
    gap: 2rem;
    margin-top: 2.6rem;
}

.feature-card .feature-last .btn-buy{
    border-radius: 46px;
    background: #E99B07;
    height: 9.2rem;
    width: 100%;
    border: none;
    color: #05294C;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
}

.feature-card .feature-last p{
    color: #7EACE3;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (width<=1280px) {
    .feature-card {
        padding: 3rem;
    }
    .feature-card .feature-card-title{
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; /* 51.2px */
    }
    .single-list{
        display: flex;
        gap: 1.5rem;
        /* font-size: 1.7rem; */
        line-height: normal;
    }
    .feature-lists{
        display: grid;
        gap: 1.8rem;
        margin-top: 4.7rem;
    }
    .feature-card .real-price{
        color: #AAA;
        font-size: 25px;
    }

    .feature-card .offer-price{
        color: #FCCA68;
        font-family: Inter, serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }

    .feature-card .feature-last .btn-buy{
        height: 7rem;
        font-size: 25px;
        font-weight: 900;
    }
}

@media (width<=1024px) {
    .feature-card {
        padding: 2.2rem;
    }
}

@media(width<768px){
    .single-list .image-size{
        width: 26px;
    }
}



@media (width<=375px) {
    .feature-card{
        padding-inline: 1.5rem;
    }
    .feature-card .real-price{
        font-size: 20px;
    }
    .feature-card .offer-price{
        color: #FCCA68;
        font-family: Inter, serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
}

@media(width>1200px) and (width<1550px){
    .feature-lists {
        gap: 1.2rem;
        margin-top: 2.5rem;
    }
}
@media(width>1200px) and (width<1550px){
    .feature-card .offer-price {
        font-size: 45px;
    }
    .feature-card .real-price {
        font-size: 30px;
    }
}

@media(width>1200px) and (width<1550px){
    .feature-card .feature-last .btn-buy {
        height: 6.2rem;
        font-size: 3rem;
        font-weight: 800;
    }
}