.total-achievement-container {
  background: #021a31;
  padding: 8rem 4rem;
}
.total-achievement-container .achievement-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1326px;
  margin: auto;
  gap: 5rem;
}
.achievement-main-container .main-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 100%;
}
.main-header-container .left-container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  justify-content: center;
  align-items: center;
}
.main-header-container .left-container .left-text {
  max-width: 646px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 45px; /* 150% */
  width: 100%;
}
.main-header-container .left-container .image-style{
  width: 100%;
}
.main-header-container .right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.5rem;
}
.right-container .text-with-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.8rem;
  flex-direction: column;
}
.text-with-button-container .insider-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.7rem;
}
.text-with-button-container .insider-texts .subtext {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.insider-texts .header-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
}
.insider-texts .header-text .first-text {
  color: #fcca68;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  max-width: 649px;
}
.header-text .subtext .second-text {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 654px;
}

.insider-texts .third-text {
  color: #7eace3;
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: line-through;
  max-width: 649px;
}
.insider-texts .span-text {
  color: #fcca68;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.text-with-button-container .buy-button {
  max-width: 424px;
  width: 100%;
  height: 102px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #e99b07;
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.25);
  color: #05294c;
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;
}
.right-container .fourth-text{
    max-width: 646px;
    color: #7EACE3;
text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.insider-texts .middle-txt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.achievement-main-container .disclaimer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3.5rem;
    flex-direction: column;
}
.disclaimer-container .disclaimer-header-and-subtext{
    display: flex;
    flex-direction: column;
    gap: 2.1rem;
}
.disclaimer-header-and-subtext .Disclaimer-border{
    color: #7EACE3;
text-align: center;
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: 45px;
text-transform: uppercase;
}

.disclaimer-container .line{
    max-width: 1326px;
height: 1px;
background: #828282;
width: 100%;
}
.disclaimer-header-and-subtext .disclaimer-subtext{
    color: #7EACE3;
text-align: center;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.disclaimer-container .footer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}
.disclaimer-container .footer p{
    color: #7EACE3;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
}

@media(width<1200px){
  .achievement-main-container .main-header-container {
    flex-direction: column;
  }
  .total-achievement-container {
    padding: 4rem;
  }
}
@media(width<768px){
  .main-header-container .left-container .left-text {
    font-size: 25px;
  }
  .insider-texts .header-text .first-text {
    font-size: 35px;
  }
  .header-text .subtext .second-text {
    font-size: 27px;
  }
  .insider-texts .third-text {
    font-size: 31px;
  }
  .insider-texts .span-text {
    font-size: 31px;
  }
  .text-with-button-container .buy-button {
    height: 60px;
    font-size: 34px;
  }
  .total-achievement-container {
    padding: 4rem 2rem;
  }
}

@media(width>1200px) and (width<1550px){
  .text-with-button-container .buy-button {
    height: 80px;
  }
  .insider-texts .header-text .first-text {
    font-size: 30px;
  }
  .header-text .subtext .second-text {
    font-size: 24px;
  }
  .insider-texts .third-text {
    font-size: 30px;
  }
  .insider-texts .span-text {
    font-size: 30px;
  }
  .main-header-container .left-container .left-text {
    font-size: 20px;
    line-height: unset;
  }
  .disclaimer-header-and-subtext .Disclaimer-border {
    font-size: 30px;
  }
}