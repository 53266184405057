body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Toastify__toast-theme--light {
  /* background-image: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(206,212,162,1) 80%) !important; */
  background-color: #1e375e !important;
  border-radius:2rem !important;
  width: 400px !important;
}
.Toastify__close-button--light {
  opacity: 1 !important;
}
@media(width<768px){
  .Toastify__toast-theme--light {
  width: 380px !important;
  }
}
@media(width<450px){
  .Toastify__toast-theme--light {
  width: 100% !important;
  }
}