.total-review-background {
  background: linear-gradient(180deg, #48638d 0%, #04284b 100%);
}
.total-review-background .actual-review-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.2rem;
  padding: 8rem 4rem;
  max-width: 1200;
  flex-direction: column;
}
.actual-review-content .review-header {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 77px; /* 120.313% */
  max-width: 1084px;
}
.actual-review-content .review-header span {
  color: #fece72;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: 77px;
}
.actual-review-content .review-image-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4.9rem;
  max-width: 1400px;
}
.review-image-grid-container .image-wrapper {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
}
.image-wrapper .image-style{
  width: 100%;
}

.single-review-contatiner{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  padding: 1.5rem;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.25);
}
.single-review-contatiner .reviewer-name{
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  padding-inline: 1.5rem;
}
.single-review-contatiner .reviewer-name p{
text-align: end;
font-size: 2rem;
font-weight: 700;
}
.reviewer-name .review-image{
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.single-review-contatiner h1{
  font-size: 1.8rem;
  text-align: center;
  font-weight: 500;
}
@media(width<1400px){
  .actual-review-content .review-header {
    font-size:60px;
  }
  .actual-review-content .review-header span {
    font-size: 60px;
  }
}
@media(width<1200px){
  .total-review-background .actual-review-content {
    padding: 4rem;
  }
  .actual-review-content .review-image-grid-container {
    gap: 1rem;
  }
}
@media(width<1000px){
  .actual-review-content .review-header {
    font-size: 45px;
    line-height: 45px;
  }
  .actual-review-content .review-header span {
    font-size: 45px;
    line-height: 70px;
}
}
@media(width<760px){
  .actual-review-content .review-image-grid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .total-review-background .actual-review-content {
    padding: 4rem 2rem;
  }
}
@media(width<600px){
  .actual-review-content .review-header {
    font-size: 35px;
    line-height: 45px;
  }
  .actual-review-content .review-header span {
    font-size: 35px;
    line-height: 45px;
}
}
@media(width<400px){
  .total-review-background .actual-review-content {
    padding: 2rem 1rem;
  }
}