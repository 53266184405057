.total-benefit-background {
  background: #fff;
}
.total-benefit-background .buy-now-button-with-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2.8rem;
  padding: 9.5rem 4rem;
}
.buy-now-button-with-text .text {
  color: #06294d;
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%; /* 54.72px */
  text-transform: capitalize;
  max-width: 1100px;
}
.total-benefit-background .bundil-benefits {
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
  justify-content: center;
  align-items: center;
  border-radius: 152px;
  background: #05294c;
  box-shadow: 0px 13px 8.3px 0px rgba(0, 0, 0, 0.25);
  padding: 10rem 4rem;
  max-width: 1326px;
  margin: auto;
}
.bundil-wrapper{
  padding: 0 2rem;
}
.bundil-benefits .header-text {
  color: #fcca68;
  text-align: center;
  font-family: Poppins;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.bundil-benefits .benefits-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* justify-content: center;
    align-items: center; */
}
.benefits-container .icon-with-benefit {
  display: flex;
  /* align-items: center; */
  gap: 2.1rem;
}
.benefits-container .icon-with-benefit .benefit-icon-size {
  font-size: 3.6rem;
  color: #fcca68;
  flex-shrink: 0;
}
.icon-with-benefit .benefit-text {
  color: #8dabda;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 66px;  */
  max-width: 984px;
}
.buy-now-button-with-text-another{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 3.8rem;
    padding: 9.5rem 4rem;
}
.buy-now-button-with-text-another .regret-text {
  color: #363636;
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 152%; /* 54.72px */
  text-transform: capitalize;
  max-width: 1100px;
}
.buy-now-button-with-text-another .regret-text span {
  color: #06294d;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%;
  text-transform: capitalize;
}

@media(width<=768px){
  .buy-now-button-with-text-another .regret-text {
    font-size: 30px;
  }
  .bundil-benefits .header-text {
    font-size: 40px;
  }
  .icon-with-benefit .benefit-text {
    font-size: 20px;
  }
  /* .benefits-container .icon-with-benefit .benefit-icon-size {
    font-size: 2.6rem;
    flex-shrink: 0;
  } */
  .total-benefit-background .bundil-benefits {
    padding:6rem 4rem;
  }
  .total-benefit-background .buy-now-button-with-text {
    padding: 4rem;
  }
  .buy-now-button-with-text .text {
    font-size: 30px;
  }
  .buy-now-button-with-text-another{
    padding: 4rem;
  }
}
@media(width<600px){
  .total-benefit-background .bundil-benefits {
    padding:10rem 2rem;
  }
  .bundil-benefits .header-text {
    font-size: 30px;
  }
  .total-benefit-background .buy-now-button-with-text {
    padding: 4rem 2rem;
  }
  .buy-now-button-with-text .text {
  font-size: 25px;
}
.icon-with-benefit .benefit-text {
  font-size: 15px;
}
}


@media(width>1200px) and (width<1550px){
  .buy-now-button-with-text .text {
    font-size: 30px;
  }
  .total-benefit-background .bundil-benefits {
    gap: 1.5rem;
  }
  .bundil-benefits .benefits-container {
    gap: 1.5rem;
  }
  .total-benefit-background .bundil-benefits {
    padding: 5rem 4rem;
  }
  .buy-now-button-with-text-another .regret-text, .buy-now-button-with-text-another .regret-text span  {
    font-size: 3rem;
  }
  .buy-now-button-with-text-another {
    padding: 5.5rem 4rem;
  }
}