.n-ebook-background {
  background: linear-gradient(180deg, #4b658f 0%, #002447 100%);
}
.n-ebook-background .header-content-content {
  /* display: flex; */
  /* gap: 8.8rem; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}
.n-ebook-background .header-content-content .header-text {
  color: #fff;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 75px;
  max-width: 498px;
}
.n-ebook-background .header-content-content .header-text .span-1 {
  color: #fecf73;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: 75px;
}
.n-ebook-background .header-content-content .header-text .span-2 {
  color: #fff;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: 75px;
}
.n-ebook-background .header-content-content .header-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 8rem 0 0 13rem;
}
.n-ebook-background
  .header-content-content
  .header-text-wrapper
  .header-second-text {
  color: #fff;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 44px; /* 122.222% */
  max-width: 503px;
}

.header-content-content .header-book-image {
  max-width: 837px;
  width: 100%;
  height: 594px;
  padding-right: 14rem;
  @media(width<1200px){
    display: none;
  }
}
.header-book-image-mobile{
  display: none;
  @media(width<1200px){
    display: block;
    max-width: 837px;
    width: 100%;
    height: 594px;
  }
  @media(width<768px){
    height: 300px;
  }
}
.header-details-total-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-bottom: 6.2rem;
  max-width: 1600px;
  margin: auto;
}
.n-ebook-background .total-header-container-button-wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 5.2rem;
  justify-content: center;
  align-items: center;
}
.total-header-container-button-wrapper .wrapping-with-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  justify-content: center;
  align-items: center;
}
.wrapping-with-text-wrapper .rating-with-users {
  display: flex;
  border-radius: 95.5px;
  background: #153758;
  max-width: 933px;
  padding: 3.2rem 6.2rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2rem;
}
.rating-with-users .first-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.5rem;
}

.first-container .insider-text {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
}
.first-container .insider-text .first-text {
  color: #7eace3;
  text-align: center;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.first-container .insider-text .second-text {
  color: #7eace3;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.wrapping-with-text-wrapper .impact-texts-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.impact-texts-container .details-text-container {
  display: flex;
  align-items: center;
  gap: 0.9rem;
}
.details-text-container .icon-style {
  font-size: 3.2rem;
  color: #7eace3;
}
.details-text-container .details-text {
  color: #7eace3;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 160% */
  max-width: 430px;
}
.mobile-view {
  display: none;
  width: 100%;
}
.desktop-view{
  width: 100%;
  justify-content: center;
  display: flex;
}
@media (width<1250px) {
  .header-content-content .header-book-image {
    height: 450px;
  }
}
@media (width<1200px) {
  .image-position-set{
    margin: auto;
  }
  .n-ebook-background .header-content-content {
    grid-template-columns: 1fr;
    margin: auto;
    padding: 4rem 0 0 0;
  }
  .n-ebook-background .header-content-content .header-text-wrapper {
    justify-content: center;
    align-items: center;
    padding: unset;
  }
  .n-ebook-background .header-content-content .header-text {
    max-width: unset;
    text-align: center;
  }
  .n-ebook-background
    .header-content-content
    .header-text-wrapper
    .header-second-text {
    max-width: unset;
    text-align: center;
  }
  .header-book-image {
    margin: auto;
  }
}

@media (width<1024px) {
  .first-container .insider-text .first-text {
    font-size: 40px;
  }
  .first-container .insider-text {
    gap: unset;
  }
  .rating-with-users .first-container {
    gap: 2rem;
  }
  .details-text-container .icon-style {
    font-size: 2.2rem;
  }
  .details-text-container .details-text {
    font-size: 18px;
  }
  .first-container .vector-image {
    width: 70px;
  }
  .header-details-total-wrapper {
    padding: 4rem 4rem;
  }
  .header-content-content .header-book-image {
    height: 400px;
  }
}
@media (width<768px) {
  .header-details-total-wrapper {
    padding: 0rem 2rem 4rem 2rem;
  }
  .rating-with-users .first-container {
    padding: 1rem;
  }
  .wrapping-with-text-wrapper .rating-with-users {
    /* flex-direction: column; */
    padding: 3.2rem;
  }
  .details-text-container .details-text {
    line-height: unset;
  }
  .header-content-content .header-book-image {
    height: 350px;
    /* display: none; */
  }
  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: block;
    width: 100%;
  }
  /* .wrapping-with-text-wrapper .rating-with-users {
    border-radius: 20%;
  } */

}
@media (width<600px) {
  .first-container .vector-image {
    width: 30px;
  }
  .first-container .insider-text .first-text {
    font-size: 20px;
  }
  .first-container .insider-text .second-text {
    font-size: 15px;
  }
/* additional-style */
.n-ebook-background .header-content-content .header-text {
  font-size: 30px;
  line-height: unset;
}
.n-ebook-background .header-content-content .header-text .span-2 {
  font-size: 30px;
  line-height: unset;
}
.n-ebook-background .header-content-content .header-text .span-1 {
  line-height: unset;
  font-size: 30px;
}
.n-ebook-background .header-content-content .header-text-wrapper .header-second-text {
  font-size: 25px;
  line-height: unset;
}
.n-ebook-background .header-content-content .header-text-wrapper {
  gap: 2rem;
}
.wrapping-with-text-wrapper .rating-with-users {
  padding: 1.2rem;
}
.details-text-container .details-text {
  font-size: 15px;
}
}

@media(width<500px){
  .wrapping-with-text-wrapper .impact-texts-container {
    flex-direction: column;
  }
  .wrapping-with-text-wrapper .rating-with-users {
    flex-direction: column;
  }
  .wrapping-with-text-wrapper .rating-with-users {
    border-radius: 20%;
  }
}
@media(width<450px){
  .header-content-content .header-book-image {
    height: 280px;
  }
}

@media(width>1200px) and (width<1550px){
  .n-ebook-background .header-content-content .header-text .span-1 , .n-ebook-background .header-content-content .header-text .span-2 , .n-ebook-background .header-content-content .header-text {
    font-size: 36px;
    line-height: unset;
  }
  .header-content-content .header-book-image {
    height: 440px;
  }
  .n-ebook-background .header-content-content .header-text-wrapper .header-second-text {
    font-size: 34px;
  }
  .n-ebook-background .header-content-content .header-text-wrapper {
    padding: 6rem 0 0 13rem;
}
.header-details-total-wrapper {
  gap: 0rem;
}
.first-container .insider-text .first-text {
  font-size: 35px;
}
.wrapping-with-text-wrapper .rating-with-users {
  padding: 2.2rem 4.2rem;
}
.n-ebook-background .total-header-container-button-wrapper {
  gap: 2rem;
}
.first-container .insider-text {
  gap: 0.3rem;
}
.first-container .vector-image{
  width: 80px;
}
}