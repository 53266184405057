.notification{
    /* background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1)); */
    display: flex;
    align-items: center;
    gap: 2rem;
}

.notification .text-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
}

.notification .notification-image{
    width: 20%;
    height: 50px;
}

.notification .text-container p{
    max-width: 400px;
    align-items: center;
    color: #ffffff;
    font-size: 1.5rem;
}
.notification .text-container h1{
    max-width: 400px;
    align-items: center;
    color: #ffffff;
    font-size: 1.5rem;
}
.notification .text-container h2{
    max-width: 400px;
    align-items: center;
    color: #ffffff;
    font-size: 1.2rem;
}
